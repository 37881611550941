<template lang="pug">
include ../../../configs/template
div.container-fluid.pa-0.my-3
  div.row
    div.col-12
      v-tabs(v-model="activeTab" color="primary" grow)
        v-tab(v-for="tab in tabs" :key="tab.id" @click="tab.action()") {{$t(tab.label)}}
  PaketForm(v-if="!activeTab")
  DocumentForm(v-else)
</template>
<script>
import { checkAccess } from '@/mixins/permissions'

export default {
  components: {
    PaketForm: () => import('./Forms/Packet.vue'),
    DocumentForm: () => import('./Forms/Document.vue')
  },
  data () {
    return {
      activeTab: 0,
      tabs: [
        { id: 1, label: 'positionStatement', checkAccess: () => true, action: () => (this.activeTab = 0) },
        { id: 2, label: 'documentApplication', checkAccess: () => checkAccess('positionStatement', 'createSingleDocs'), action: () => (this.activeTab = 1) }
      ]
    }
  },
  methods: {
  }
}
</script>
